import React from 'react';
import '../../styles/work.css';

import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function Work() {
  return (
    <>
    <IconContext.Provider value={{ color: '#871357' }}>
      <div className='work__section'>
        <div className='work__wrapper'>
          <h1 className='work__heading'>Unsere Arbeiten</h1>
          <div className='work__container'>
            <Link to='#top' className='work__container-card'>
              <div className='work__container-cardInfo'>
                <div className='icon'>
                 
                </div>

                <ul className='work__container-features'>
                  <li></li>
                </ul>
              </div>
            </Link>
            <Link to='#top' className='work__container-card'>
              <div className='work__container-cardInfo'>
                <div className='icon'>

                </div>
                <h3>...und...</h3>
                <p></p>

                <ul className='work__container-features'>
                  <li></li>
                </ul>
              </div>
            </Link>
            <Link to='#top' className='work__container-card'>
              <div className='work__container-cardInfo'>
                <div className='icon'>

                </div>
                <h3>...Das...</h3>
                <p></p>

                <ul className='work__container-features'>
                  <li></li>
                </ul>
              </div>
            </Link>
            <Link to='#top' className='work__container-card'>
              <div className='work__container-cardInfo'>
                <div className='icon'>

                </div>
                <h3>...ist momentan...</h3>
                <p></p>

                <ul className='work__container-features'>
                  <li></li>
                </ul>
              </div>
            </Link>
            <Link to='#top' className='work__container-card'>
              <div className='work__container-cardInfo'>
                <div className='icon'>

                </div>
                <h3>...noch in Arbeit</h3>
                <p></p>
               
                <ul className='work__container-features'>
                <li><strong></strong></li>
                <li></li>
                </ul>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
    </>
  );
}
export default Work;