import React, { useCallback, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/gameoflife.css';
import produce from 'immer';

const numRows = 100;
const numCols = 100;

const operations = [
  [0, 1],
  [0, -1],
  [1, -1],
  [-1, 1],
  [1, 1],
  [-1, -1],
  [1, 0],
  [-1, 0]
];

const generateEmptyGrid = () => {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(Array.from(Array(numCols), () => 0));
    }

    return rows;
};

const GameOfLife = () => {
  const [grid, setGrid] = useState(() => {
  return generateEmptyGrid();
  });

  const [running, setRunning] = useState(false); 

  const runningRef = useRef(running);
  runningRef.current = running;

  const runSimulation = useCallback(() => {
    if (!runningRef.current) {
      return;
    }
    // simulate
    setGrid(g => {
      return produce(g, gridCopy => {
        for (let i = 0; i < numRows; i++) {
          for (let k = 0; k < numCols; k++) {
            let neighbors = 0;
            operations.forEach(([x, y]) => {
              const newI = i + x;
              const newK = k + y;
              if (newI >= 0 && newI < numRows && newK >= 0 && newK < numCols) {
                neighbors += g[newI][newK]
              }
            });

            if (neighbors < 2 || neighbors > 3) {
              gridCopy[i][k] = 0;
            } else if (g[i][k] === 0 && neighbors === 3) {
              gridCopy[i][k] = 1;
            }
          }
        }
      });
    });

    setTimeout(runSimulation, 100);
  }, []); 

  return (
    <>

    <Container>
      <Row>
        <Col sm>
          <h5>Conways Game of Life</h5>
            <p>Entweder einzelne Punkte im Gitter auswählen und auf start klicken.</p>
            <p>Oder Zufällig auswählen und start klicken.</p>
          <a href='https://de.wikipedia.org/wiki/Conways_Spiel_des_Lebens' target='_blank' rel='noopener noreferrer'>Mehr dazu auf Wikipedia</a>
        </Col>
        <Col sm>
          <Button variant="success" onClick={() => {
            setRunning(!running);
            if (!running) {
              runningRef.current = true;
              runSimulation();
            }
          }}
          >
            {running ? 'Stop' : 'Start'}
          </Button>


          <Button variant="info"
            onClick={() => {
              const rows = [];
              for (let i = 0; i < numRows; i++) {
                rows.push(
                  Array.from(Array(numCols), () => (Math.random() > 0.6 ? 1 : 0))
                );
              }  
              setGrid(rows);
            }}
          >
            Zufällig
          </Button>

          <Button variant="warning"
            onClick={() => {
              setGrid(generateEmptyGrid());
            }}
          >
            Löschen
          </Button>


          <div style={{ 
            display: 'grid',
            gridTemplateColumns: `repeat(${numCols}, 5px)`
          }}>
            {grid.map((rows, i) =>
              rows.map((col, k) =>
                <div
                  key={`${i}-${k}`}
                  onClick={() => {
                    const newGrid = produce(grid, gridCopy => {
                      gridCopy[i][k] = grid[i][k] ? 0 : 1;
                    })
                    setGrid(newGrid);
                  }}
                  style={{ width: 5,
                    height: 5,
                    backgroundColor: grid[i][k] ? '#f00946' : undefined,
                    border: 'solid 1px black'
                  }}
                />)
              )}
            </div>
          </Col>
          <Col sm></Col>
        </Row>
      </Container>
    </>
  );
};

export default GameOfLife;
