import React from 'react'

import Canvas from './canvas'


function Home() {

  return (
    <>

    <Canvas />

    </>
  );
}

export default Home
