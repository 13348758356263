import React from 'react'
import { FaToriiGate } from 'react-icons/fa'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import './styles/footer.css'

function Footer() {
  return (
    <>  
    <Navbar fixed='bottom' collapseOnSelect expand='lg' bg='dark' variant='dark'>
    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
    <Navbar.Collapse id='responsive-navbar-nav'>
      <Nav className='mr-auto'>
        <Nav.Item>
          <Nav.Link href="mailto:contact@noconcept.design">
            <p>| &copy; 2020 <FaToriiGate className='navbar-icon' style={{ color: '#f00946' }} /> noconcept.design | </p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/impressum">
            <p>| Impressum |</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/datenschutz">
            <p> | Datenschutz |</p>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      </Navbar.Collapse>
    </Navbar>            
    </>
  )
}

export default Footer
