import React from 'react';
import '../../styles/meinungen.css';
import { FaRegGrinHearts, FaFighterJet } from 'react-icons/fa';
import { GiSwordsPower } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';

function Meinungen() {
  return (
    <>
      <IconContext.Provider value={{ color: ' #f00946', size: 32 }}>
        <div className='meinungen__section'>
          <div className='meinungen__wrapper'>
            <h1 className='meinungen__heading'>Das sagen unsere <s>gekauften</s> treuen Kunden.</h1>
            <div className='meinungen__container'>
              <div className='meinungen__container-card'>
                <div className='meinungen__container-cardInfo'>
                  <img className='meinungen__container-avatar1' alt='Yoda' src='./images/avatar1.jpg' />
                  <div className='icon'>
                    <GiSwordsPower />
                  </div>  
                    <ul className='meinungen__container-features'>
                      <li>
                        <blockquote className='groucho'>Möge das Atmen sein mit Ihnen..
                          <footer>
                            Yoda
                          </footer>
                        </blockquote>
                        <strong>Position:</strong> gROOT
                      </li>
                    </ul>
                </div>
              </div>
              <div className='meinungen__container-card'>
                <div className='meinungen__container-cardInfo'>
                  <img className='meinungen__container-avatar2' alt='Methusalem' src='./images/avatar2.jpg' />
                  <div className='icon'>
                    <FaFighterJet />
                  </div>
                    <ul className='meinungen__container-features'>
                      <li>
                        <blockquote className='groucho'>
                          Wahnsinns Reaktionszeit!!!
                          Schneller und kompententer als Vattenfall und EnBW zusammen,
                          einfach unglaublich diese Stromer!!!
                          <footer>Methusalem</footer>
                        </blockquote>
                        <strong>Position:</strong> 2oLd4U
                      </li>
                    </ul>
                </div>
              </div>
              <div className='meinungen__container-card'>
                <div className='meinungen__container-cardInfo'>
                  <img className='meinungen__container-avatar3' alt='Kate Mara' src='./images/kate/0.png' id='imgRotate' />
                  <div className='icon'>
                    <FaRegGrinHearts />
                  </div>
                  <ul className='meinungen__container-features'>
                    <li>
                      <blockquote className='groucho'>
                        I love NOCONCEPT DESIGN - this Azubi guy is so a cute monkey.
                        <footer>
                          <a href='https://www.instagram.com/katemara' target='_blank' rel='noopener noreferrer'>Kate Mara</a>
                        </footer>
                      </blockquote>
                      <strong>Position:</strong> Top Fan
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );

}
export default Meinungen;