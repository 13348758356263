import React, {useState, useEffect} from 'react';
import '../../styles/skills.css';
import ProgressBar from '../../progressbar';
import { FaPhp, FaHammer, FaReact, FaPiedPiperAlt, FaHtml5, FaCss3, FaNodeJs} from 'react-icons/fa';
import { SiMongodb, SiPostgresql, SiMariadbfoundation} from 'react-icons/si';
import { ImLeaf } from 'react-icons/im';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';



const fullstackData = [
  { bgcolor: "#ef6c00", completed: 27.4 },
];
const webdesignData = [
  { bgcolor: "#d1250a", completed: 15 },
];
const appsData = [
  { bgcolor: "#a5f545", completed: 50 },
];
const computingData = [
  { bgcolor: "#65f0b8", completed: 75 },
];
const breathData = [
  { bgcolor: "#6a1b9a", completed: 110 },
];

function Skills() {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    setInterval(() => setCompleted(Math.floor(Math.random() * 110) + 1), 2000);
  }, []);

  return (
    <IconContext.Provider value={{ color: '#f00946' }}>
      <div className='skills__section'>
        <div className='skills__wrapper'>
          <h1 className='skills__heading'>Unsere Stärken</h1>
          <div className='skills__container'>
            <Link to='/' className='skills__container-card'>
              <div className='skills__container-cardInfo'>
                <div className='icon'>
                  <FaPhp /> <FaHtml5 /> <FaCss3 /> <FaNodeJs /> <SiMongodb /> <SiPostgresql /> <SiMariadbfoundation />
                </div>
                <h3>Full-Stack</h3>
                <p>Für so spielerein</p>
                  <div className="skills__container-pgbar">
                    {fullstackData.map((item, idx) => (
                      <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
                    ))}
                  </div>
                <ul className='skills__container-features'>
                  <li>Hat unser Hausmeister immer Zeit...</li>
                </ul>
              </div>
            </Link>
            <Link to='/' className='skills__container-card'>
              <div className='skills__container-cardInfo'>
                <div className='icon'>
                  <FaPiedPiperAlt />
                </div>
                <h3>Zeichnen/Webdesign</h3>
                <p>Malen nach Zahlen</p>
                  <div className="skills__container-pgbar">
                    {webdesignData.map((item, idx) => (
                      <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
                    ))}
                  </div> 
                <ul className='skills__container-features'>
                  <li>Zur Zeit gaanz schlecht, da sich unser <s>Azubi</s> bester Mann im Urlaub befindet.</li>
                </ul>
              </div>
            </Link>
            <Link to='/' className='skills__container-card'>
              <div className='skills__container-cardInfo'>
                <div className='icon'>
                  <FaReact />
                </div>
                <h3>Web Apps</h3>
                <p>Kann unser Reinigungspersonal</p>
                  <div className="skills__container-pgbar">
                    {appsData.map((item, idx) => (
                      <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
                    ))}
                  </div>  
                <ul className='skills__container-features'>
                  <li>Bitte vereinbaren Sie einen verbindlichen Termin.</li>
                </ul>
              </div>
            </Link>
            <Link to='/' className='skills__container-card'>
              <div className='skills__container-cardInfo'>
                <div className='icon'>
                  <FaHammer />
                </div>
                <h3>Computing</h3>
                <p>Windows/Linux und Virtualisierung</p>
                <div className="skills__container-pgbar">
                  {computingData.map((item, idx) => (
                    <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
                  ))}
                </div> 
                <ul className='skills__container-features'>
                  <li>Dafür ist unser <s>zweit bester Mann</s> Fach-Informatiker zu gebrauchen.</li>
                </ul>
              </div>
            </Link>
            <Link to='/' className='skills__container-card'>
              <div className='skills__container-cardInfo'>
                <div className='icon'>
                  <ImLeaf />
                </div>
                <h3>Atmen</h3>
                <p>Hier hat selbst der Chef noch Luft!</p>
                  <div className="skills__container-pgbar">
                    {breathData.map((item, idx) => (
                      <ProgressBar key={idx} bgcolor={item.bgcolor} completed={completed} />
                    ))}
                  </div>                
                <ul className='skills__container-features'>
                <li><strong>bis zu 110%!!</strong></li>
                <li>Für Sie und <s>Ihr</s> <strong>unser</strong> Geld!!</li>
                </ul>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Skills;