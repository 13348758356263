import React, { useState, useEffect, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

function Catmemes() {

  const [image, setImage] = useState(null)
  const canvas = useRef(null)
  const [topText, setTopText] = useState('')
  const [bottomText, setBottomText] = useState('')



  useEffect(() => {
    const catImage = new Image();
    //catImage.src = 'https://thiscatdoesnotexist.com/'
    catImage.src = 'https://localhost:3000/uploads/'
    catImage.onload = () => setImage(catImage)
  }, [])

  useEffect(() => {
    if(image && canvas) {
      const ctx = canvas.current.getContext('2d')
      ctx.fillStyle = 'black'
      ctx.fillRect(0, 0, 400, 256 + 80)
      ctx.drawImage(image, (400 - 256) / 2, 40)

      ctx.font ='20px Comic Sans MS'
      ctx.fillStyle = 'white'
      ctx.textAlign = 'center'

      ctx.fillText(topText, (400 / 2), 25)
      ctx.fillText(bottomText, (400 / 2), 256 + 40 +25)
    }
  }, [image, canvas, topText, bottomText])

  return (
    <>
      <div className='container mx-auto'>
        <h1>Katzen Meme!</h1>
        <div className='container content-center'>
          <canvas
            ref={canvas}
            width={400}
            height={256 + 80} 
          />           
        </div>



        <div className='container container-center leading-normal'>
          <input type='text' className='px-4 py-2 leading-tight'
            placeholder='Überschrift'
            value={topText}
            onChange={e => setTopText(e.target.value)}
          />
          <br />
          <input type='text' className='px-4 py-2 leading-tight'
            placeholder='Haupttext'
            value={bottomText}
            onChange={e => setBottomText(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default Catmemes;