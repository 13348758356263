import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';
import Navigation from './components/navigation';
// import Canvas from './components/canvas';
import Home from './components/home';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import Skills from './components/pages/Skills';
import Meinungen from './components/pages/Meinungen';
import Work from './components/pages/Work';
import Gameoflife from './components/pages/Gameoflife';
//import Tetris from './components/pages/Tetris/Tetris';
//import GameSection from './components/pages/Game/Game';
import Catmemes from './components/pages/Catmemes';
import Footer from './components/footer';
//import ScrollArrow from './components/scrollarrow';


function App() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route path='/' exact component={Home} />             
        <Route path='/skills' component={Skills} />                
        <Route path='/arbeiten' component={Work} />                
        <Route path='/meinungen' component={Meinungen} />
        <Route path='/gol' component={Gameoflife} />                 
        <Route path='/catmemes' component={Catmemes} />                 
        <Route path='/impressum' component={Impressum} />
        <Route path='/datenschutz' component={Datenschutz} />
      </Switch>

      <Footer />   
    </Router>
  );
}

export default App;
