import React from 'react'
import { FaToriiGate } from 'react-icons/fa'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import './styles/navigation.css'

function Navigation() {
  return (
    <>
      <Navbar sticky='top' collapseOnSelect expand='lg' bg='dark' variant='dark' style={{ background: 'transparent' }}>
        <Navbar.Brand href='/'>
          <FaToriiGate className='navbar-icon' style={{ color: '#f00946' }} /> NOCONCEPT
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link href='/skills'>Skills</Nav.Link>
            <Nav.Link href='/arbeiten'>Arbeiten</Nav.Link>
            <Nav.Link href='/meinungen'>Kundenstimmen</Nav.Link>
            <NavDropdown title='Spiele' id='collasible-nav-dropdown'>
              <NavDropdown.Item href='#action/3.2'>Tetris</NavDropdown.Item>
              <NavDropdown.Item href='/gol'>Game of Life</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/catmemes'>Katzen Memes</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Navigation
